import {useCartItem as coreUseCartItem} from "@shopware-pwa/composables-next";
import type { UseCartItemReturn } from "@shopware-pwa/composables-next";
import type {Schemas} from "~/api-types/storeApiTypes";
import type { Ref } from "vue";
import { useCart, useShopwareContext } from "#imports";
const { refreshCart } = useCart();

export const useCartItem = (cartItem: Ref<Schemas["LineItem"]>): UseCartItemReturn => {
  const coreFunctionality = coreUseCartItem(cartItem)
  const { apiClient } = useShopwareContext();

  const removeItem = async () => {
    const newCart = await apiClient.invoke(
        "removeLineItem delete /checkout/cart/line-item",
        {
          body: {
            ids: [cartItem.value.id],
          }
        },
    ).then((data) => {return data.data});
    await refreshCart(newCart);
  }

  return {
    ...coreFunctionality,
    removeItem
  };
};
